import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';

import SubmissionDetailTable from './SubmissionDetailTable';
import TooltipIconButton from '../../common/TooltipIconButton';
import { convertGradedFilePathToFileName } from '../../../utils';
import { AssignmentSubmission } from '../../../types';
import { Rubric } from '../../../types';

interface SubmissionDetailProps {
  userId?: string;
  assignmentId?: string;
  submissions?: AssignmentSubmission[] | null;
  rubrics?: Rubric[];
}

const SubmissionDetail: React.FC<SubmissionDetailProps> = ({
  userId,
  assignmentId,
  submissions,
  rubrics,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  if (!assignmentId || !submissions || !submissions.length || !rubrics) {
    return null;
  }

  const submissionCount = useMemo(() => {
    return submissions.length ?? 0;
  }, [submissions]);

  const currentSubmission = useMemo(() => {
    if (selectedIndex < 0) return undefined;
    return submissions[selectedIndex];
  }, [submissions, selectedIndex]);

  const handleNext = () => {
    setSelectedIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      return newIndex < submissionCount ? prevIndex + 1 : prevIndex;
    });
  };

  const handlePrev = () => {
    setSelectedIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      return newIndex >= 0 ? newIndex : prevIndex;
    });
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <TooltipIconButton
          tooltip="Previous"
          icon={ArrowBackIosNewIcon}
          onClick={handlePrev}
          disabled={selectedIndex === 0}
        />
        <div className="flex flex-col items-center justify-center">
          <Link
            to={`/assignments/${assignmentId}?userId=${userId}&fileName=${currentSubmission?.student_id}.json`}
          >
            <h1 className="text-xl font-bold text-blue-600 hover:text-blue-800">
              Submission #{selectedIndex + 1}
            </h1>
          </Link>
          <span className="text-md text-center">
            {convertGradedFilePathToFileName(
              currentSubmission?.submission_path || ''
            )}
          </span>
        </div>
        <TooltipIconButton
          tooltip="Next"
          icon={ArrowForwardIosIcon}
          onClick={handleNext}
          disabled={selectedIndex === submissionCount - 1}
        />
      </div>
      <SubmissionDetailTable rubrics={rubrics} submission={currentSubmission} />
    </div>
  );
};

export default SubmissionDetail;
