import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { createRubric } from './createRubric';
import { GCCourseView } from './GCCourseView';
import { useApi } from '../../contexts/ApiProvider';
import { useCurrentAssignment } from '../../contexts/CurrentAssignmentContext';
import {
  AssignmentMetadataContentV1,
  GoogleClassroomCourse,
  GoogleClassroomCourseWork,
} from '../../types';
import { scrollToTop } from '../../utils';
import { isProduction } from '../../constants';

export const GCAssignmentSelector: React.FC = () => {
  const location = useLocation();
  const { api } = useApi();
  const { setAssignmentMetadataContent } = useCurrentAssignment();
  const [googleClassroomCourses, setGoogleClassroomCourses] = useState<
    GoogleClassroomCourse[]
  >([]);
  const [selectedCourse, setSelectedCourse] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const retryAfterConsent = localStorage.getItem('retryAfterConsent');
    if (retryAfterConsent === 'true') {
      localStorage.removeItem('retryAfterConsent');

      const queryParams = new URLSearchParams(location.search);
      const error = queryParams.get('error');
      if (!error) {
        handleImport();
      }
    }
  }, []);

  const handleImport = async () => {
    const userId = api.getCurrentUserId();
    const currentLocation = window.location.href.split('?')[0];
    setIsLoading(true);
    try {
      const courseURL = isProduction
        ? '/api/v1/import_courses'
        : 'http://localhost:8083';
      const { data } = await axios.get<GoogleClassroomCourse[]>(
        `${courseURL}?user_id=${userId}`
      );
      setGoogleClassroomCourses(data);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.setItem('retryAfterConsent', 'true');
        const consentURL = isProduction
          ? '/api/v1/oauth2authorize'
          : 'http://localhost:8082';
        window.location.href = `${consentURL}?user_id=${userId}&original_url=${currentLocation}`;
      } else {
        console.error('error', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickCourse = (id: string) => {
    if (id === selectedCourse) setSelectedCourse(undefined);
    else setSelectedCourse(id);
  };

  const handleSelectAssignment = (
    courseTitle: string,
    assignment: GoogleClassroomCourseWork,
    courseId: string
  ) => {
    const newContent = new AssignmentMetadataContentV1(
      '',
      '',
      '',
      courseTitle,
      assignment.description || '',
      assignment.title,
      [createRubric()],
      undefined,
      {
        course_work_id: assignment.id,
        course_id: courseId,
      }
    );

    setAssignmentMetadataContent(newContent);
    scrollToTop();
  };

  return (
    <>
      <div className="mx-auto max-w-5xl p-6 space-y-6 bg-white rounded-md">
        <h1 className="text-xl font-semibold">
          Select Google Classroom Assignment
        </h1>
        <p>
          Pick an assignment from your Google Classroom to reuse. Once selected,
          the form above will be filled with the assignment details, allowing
          you to use it as is or modify it to suit your needs. If the assignment
          you need isn't listed, create a new one above.
        </p>
        <button
          onClick={handleImport}
          disabled={isLoading}
          className={`${
            isLoading ? 'owler-disabled-button' : 'owler-indigo-button'
          }`}
        >
          Import From Google Classroom
        </button>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <ul role="list" className="space-y-2">
            {!googleClassroomCourses.length &&
              'No Google Classroom data is available'}
            {googleClassroomCourses.map((course) => (
              <li
                key={course.id}
                className={`owler-li-bubble owler-border-gray-lg ${
                  course.id === selectedCourse
                    ? 'bg-indigo-50'
                    : 'hover:bg-gray-50'
                }`}
              >
                <GCCourseView
                  course={course}
                  isSelected={course.id === selectedCourse}
                  onClick={handleClickCourse}
                  onSelect={handleSelectAssignment}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};
