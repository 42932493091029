import { AssignmentMetadatSection } from '../types';
export const orgTabLabels = ['Assignments'];

export const orgAssignmentMetadataSections: AssignmentMetadatSection[] = [
  {
    key: 'row-details',
    items: [
      {
        key: 'department',
        label: 'Department',
      },
      {
        key: 'course',
        label: 'Course',
      },
      {
        key: 'teacher_type',
        label: 'Teacher Type',
      },
      {
        key: 'grade_level',
        label: 'Grade Level',
      },
    ],
  },
  {
    key: 'row-question',
    items: [
      {
        key: 'question',
        label: 'Question',
      },
    ],
  },
  {
    key: 'question-background',
    items: [
      {
        key: 'question_background',
        label: 'Question Background',
      },
    ],
  },
];
