import { AssignmentScoreLabel } from '../types';

export const assignmentScores: AssignmentScoreLabel[] = [
  {
    key: 'mean',
    label: 'Mean',
  },
  {
    key: 'median',
    label: 'Median',
  },
  {
    key: 'mode',
    label: 'Mode',
  },
];
