import React from 'react';

import { orgAssignmentMetadataSections } from '../../../constants';
import { AssignmentMetadataContent } from '../../../types';

interface AssignmentMetaDataProps {
  metadata?: AssignmentMetadataContent | null;
}

const AssignmentMetaData: React.FC<AssignmentMetaDataProps> = ({
  metadata,
}) => {
  if (!metadata) {
    return null;
  }

  return (
    <div>
      {orgAssignmentMetadataSections.map(({ items, key }) => (
        <div key={key} className="flex gap-4">
          {items.map(({ key, label }) => (
            <div key={key}>
              <span className="font-bold">{label}: </span>
              {metadata[key]}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AssignmentMetaData;
