import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

import { formatNumber } from '../../../utils';
import { Rubric } from '../../../types';
import { AssignmentSubmission, SubmissionDetailTableRow } from '../../../types';

interface SubmissionDetailTableProps {
  rubrics?: Rubric[];
  submission?: AssignmentSubmission;
}

const SubmissionDetailTable: React.FC<SubmissionDetailTableProps> = ({
  rubrics,
  submission,
}) => {
  const mainRows = useMemo(
    () =>
      (rubrics || []).map(
        ({ id, name, description, points_possible }) =>
          ({
            id,
            name,
            description,
            points_possible: formatNumber(Number(points_possible)),
            points_earned: formatNumber(
              Number(submission?.output.rubric_grading[name].points_earned) || 0
            ),
          }) as SubmissionDetailTableRow
      ),
    [rubrics, submission]
  );

  const rows: SubmissionDetailTableRow[] = mainRows;
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Rubric Name',
      width: 200,
      colSpan: ({ row }) => {
        if (row.id === 'total') {
          return 2;
        }
        return undefined;
      },
    },
    { field: 'description', headerName: 'Rubric Description', width: 550 },
    {
      field: 'points_possible',
      headerName: 'Points Possible',
      width: 150,
    },
  ];

  return (
    <div className="w-full flex justify-center">
      <Box
        sx={{
          width: '90%',
        }}
      >
        <DataGridPro
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              pageSize: 5,
            },
          }}
          pagination
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          autoHeight
          experimentalFeatures={{ rowPinning: true }}
        />
      </Box>
    </div>
  );
};

export default SubmissionDetailTable;
