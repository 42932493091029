import React, { useEffect, useState } from 'react';

import { useFetchSingleGradingResult } from '../../utils/useFetchSingleGradingResult';
import { type Assignment, type AssignmentMetadataContent } from '../../types';
import { convertGradedFilePathToFileName } from '../../utils';
import ReviewFeedback from './ReviewFeedback';
import ReviewStudentSubmission from './ReviewStudentSubmission';
import { useGradingResult } from './GradingResultContext';

type ReviewDetailProps = {
  userId: string | null;
  assignment: Assignment;
  outputPath: string;
  submissionId?: string;
};

const ReviewDetail: React.FC<ReviewDetailProps> = ({
  userId,
  assignment,
  outputPath,
  submissionId,
}) => {
  const { gradingResult, setGradingResult } = useGradingResult();

  const {
    gradingResult: fetchedGradingResult,
    isLoading,
    isFetching,
    error,
  } = useFetchSingleGradingResult(assignment, outputPath, submissionId, userId);

  useEffect(() => {
    // Call the context's setGradingResult when fetchedGradingResult updates
    if (!isLoading && fetchedGradingResult && !error) {
      setGradingResult(fetchedGradingResult);
    }
  }, [fetchedGradingResult, isLoading, error, setGradingResult]);

  if (outputPath !== gradingResult?.outputFileName) {
    return (
      <div className="flex flex-col h-screen justify-center items-center">
        Loading...
      </div>
    );
  }

  if (!gradingResult || isLoading || isFetching) {
    return (
      <div className="flex flex-col h-screen justify-center items-center">
        Loading...
      </div>
    );
  }

  return (
    <ReviewDetailPartial
      assignmentMetadataContent={assignment.metadata_content}
      assignmentId={assignment.assignment_id}
      submissionId={submissionId}
      isEditable={submissionId !== undefined}
    />
  );
};

interface ReviewDetailPartialProps {
  assignmentMetadataContent: AssignmentMetadataContent;
  assignmentId: string;
  submissionId?: string;
  isEditable?: boolean;
}

const ReviewDetailPartial: React.FC<ReviewDetailPartialProps> = ({
  assignmentMetadataContent,
  assignmentId,
  submissionId,
  isEditable,
}) => {
  const { gradingResult } = useGradingResult();
  if (!gradingResult) {
    return <div>Loading...</div>;
  }

  const fileName = gradingResult.inputPath;
  if (!fileName) {
    throw new Error(
      '`ReviewDetailPartial` requires a `gradingResult.inputPath`.'
    );
  }

  return (
    <div className="flex flex-col h-screen">
      <div className="overflow-hidden rounded-lg bg-white flex flex-col h-full">
        {/* Two side-by-side containers */}
        <div className="flex flex-col md:flex-row flex-grow h-[50vh] md:h-[80vh]">
          {/* First container */}
          <div className="flex-1 p-6 mb-2 md:mb-0 overflow-y-auto">
            {gradingResult.inputFileContent && (
              <ReviewStudentSubmission
                filename={convertGradedFilePathToFileName(fileName)}
                studentSubmission={gradingResult.inputFileContent}
                rubricGrading={gradingResult.rubricGrading}
                grammarMistakes={gradingResult.grammarMistakes}
              />
            )}
            {!gradingResult.inputFileContent && (
              <div>Error: Owler output does not exist</div>
            )}
          </div>

          {/* Second container */}
          <div className="flex-1 p-6 overflow-y-auto">
            <ReviewFeedback
              assignmentMetadataContent={assignmentMetadataContent}
              assignmentId={assignmentId}
              submissionId={submissionId}
              isEditable={isEditable}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ReviewDetail, ReviewDetailPartial };
