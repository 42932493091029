import React from 'react';
import { Box } from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  gridClasses,
} from '@mui/x-data-grid-pro';

import AssignmentDetail from './AssignmentDetail';
import CustomToolbar from '../../common/CustomDataGridToolbar';
import Loading from '../../common/Loading';
import CustomNoRowsOverlay from '../../common/CustomDataGridNoRowsOverlay';
import { getFormattedDate } from '../../../utils';
import { AssignmentRow, AssignmentWithUserId } from '../../../types';

interface AssignmentDataGridProps {
  rows?: AssignmentRow[];
  rawData?: AssignmentWithUserId[];
  isLoading: boolean;
}

const columns: GridColDef[] = [
  { field: 'teacherEmail', headerName: 'Teacher Email', width: 180 },
  { field: 'teacherName', headerName: 'Teacher Name', width: 150 },
  { field: 'course', headerName: 'Course', width: 200 },
  { field: 'assignmentName', headerName: 'Assignment', width: 350 },
  { field: 'submissionCount', headerName: 'Submission', width: 150 },
  { field: 'evaluationTime', headerName: 'Evaluation Time', width: 150 },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 150,
    renderCell: (params: GridRenderCellParams<Date>) => (
      <span>{getFormattedDate(params.value)}</span>
    ),
  },
];

const AssignmentDataGrid: React.FC<AssignmentDataGridProps> = ({
  rows,
  rawData,
  isLoading,
}) => {
  if (isLoading) {
    return <Loading />;
  }

  if (!rows || !rawData) {
    return null;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            pageSize: 10,
          },
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
        pagination
        rowsPerPageOptions={[10, 20, 50]}
        disableSelectionOnClick
        autoHeight
        getRowHeight={() => 'auto'}
        getDetailPanelContent={({ row }) => (
          <AssignmentDetail
            data={rawData.find((item) => item.assignment_id === row.id)}
          />
        )}
        getDetailPanelHeight={() => 'auto'}
        components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
      />
    </Box>
  );
};

export default AssignmentDataGrid;
