export const getFormattedDate = (date?: Date): string => {
  if (!date) return '';

  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const formatNumber = (number: number): number =>
  parseFloat(number.toFixed(2));
