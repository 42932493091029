import React from 'react';

import CardContent from '../../components/common/ContentCard';
import AssignmentDataGrid from '../../components/Organization/assignments/AssignmentDataGrid';
import { useAssignmentDataGridData } from '../../utils/useAssignmentDataGridData';
import { Organization } from '../../types';

interface OrgAssignmentsProps {
  orgData: Organization;
}

const OrgAssignments: React.FC<OrgAssignmentsProps> = ({ orgData }) => {
  const { data, rawData, isLoading } = useAssignmentDataGridData(
    orgData.members
  );

  return (
    <CardContent
      children={
        <AssignmentDataGrid
          rows={data}
          rawData={rawData}
          isLoading={isLoading}
        />
      }
      title="Assignments"
    />
  );
};

export default OrgAssignments;
