import { useState, useEffect } from 'react';
import { Assignment } from '../../../types';
import { FirebaseStorage, listAll, ref } from 'firebase/storage';
import {
  Firestore,
  QuerySnapshot,
  CollectionReference,
  collection,
  doc,
  getDocs,
  getDoc,
  orderBy,
  query,
} from 'firebase/firestore';
import { AssignmentReference } from '../AssignmentList/AssignmentReference';
import { Submission, GradingResult } from '../../../types';

const useSubmissions = (
  submissionsProvider: SubmissionsProvider,
  assignmentReference?: AssignmentReference
) => {
  const [submissions, setSubmissions] = useState<Submission[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (!assignmentReference) {
      return;
    }

    submissionsProvider
      .getSubmissions(assignmentReference)
      .then(setSubmissions);
  }, [assignmentReference, submissionsProvider]);

  return submissions;
};

function trimLeadingPath(fileName: string): string {
  return fileName.split('/').pop()!;
}

export class FirebaseSubmissionsProvider implements SubmissionsProvider {
  constructor(
    private readonly _firebaseStorage: FirebaseStorage,
    private readonly _firestore: Firestore,
    private readonly _userId: string
  ) {}

  async getSubmissions(
    assignmentReference: AssignmentReference
  ): Promise<Submission[] | undefined> {
    // try to get submissions from the submissions collection
    const submissions =
      await this.getSubmissionsFromSubmissionsCollection(assignmentReference);
    if (submissions) {
      return submissions;
    }

    // otherwise use the grading job id to fetch submissions
    return this.getSubmissionsForGradingJobId(
      assignmentReference.grading_job_id
    );
  }

  async getSubmissionsSnapshot(
    assignmentReference: AssignmentReference
  ): Promise<{
    submissionsColRef: CollectionReference;
    submissionsSnapshot: QuerySnapshot;
  } | null> {
    try {
      const assignmentDocRef = doc(
        this._firestore,
        'users',
        this._userId,
        'assignments',
        assignmentReference.assignment_id
      );

      if (!assignmentDocRef) {
        return null;
      }

      const submissionsColRef = collection(assignmentDocRef, 'submissions');
      const submissionsSnapshot = await getDocs(submissionsColRef);

      return { submissionsColRef, submissionsSnapshot };
    } catch (error) {
      console.log('Error getting submission snapshot: ', error);
      return null;
    }
  }

  async getSubmissionScores(
    assignmentReference: AssignmentReference
  ): Promise<number[]> {
    try {
      const submissionsData =
        await this.getSubmissionsSnapshot(assignmentReference);

      if (!submissionsData || submissionsData.submissionsSnapshot.empty) {
        return [];
      }

      const { submissionsColRef, submissionsSnapshot } = submissionsData;

      const submissionPromises = submissionsSnapshot.docs.map(async (item) => {
        const gradingOutputDocRef = doc(
          submissionsColRef,
          item.id,
          'output',
          'gradingOutput'
        );
        const gradingOutputDocSnap = await getDoc(gradingOutputDocRef);
        const data = gradingOutputDocSnap.data() as GradingResult;
        return parseFloat(data.grade.replace('%', ''));
      });

      const submissionScores = await Promise.all(submissionPromises);
      return submissionScores;
    } catch (error) {
      console.log('Error fetching submission scores: ', error);
      return [];
    }
  }

  async getSubmissionsFromSubmissionsCollection(
    assignmentReference: AssignmentReference
  ): Promise<Submission[] | undefined> {
    // check if firebaseAssignment has a submissions collection
    try {
      const submissionsData =
        await this.getSubmissionsSnapshot(assignmentReference);

      if (!submissionsData || submissionsData.submissionsSnapshot.empty) {
        return undefined;
      }

      const { submissionsSnapshot } = submissionsData;

      return submissionsSnapshot.docs.map((doc) => {
        const firebaseSubmission = doc.data();
        return {
          fileName: trimLeadingPath(firebaseSubmission.output_path),
          submissionId: doc.id,
          outputPath: firebaseSubmission.output_path,
          submissionPath: firebaseSubmission.submission_path,
          token: firebaseSubmission.token,
        };
      });
    } catch (error) {
      console.log('Error fetching submissions: ', error);
    }

    return undefined;
  }

  async getSubmissionsForGradingJobId(
    gradingJobId: string
  ): Promise<Submission[] | undefined> {
    const folderPath = `users/${this._userId}/output/${gradingJobId}`;
    const storageReference = ref(this._firebaseStorage, folderPath);
    const listResult = await listAll(storageReference);

    const fileNames = listResult.items.map((item) => item.name);

    return fileNames.map((fileName) => {
      return { fileName: fileName };
    });
  }
}

export interface SubmissionsProvider {
  getSubmissions(
    assignmentReference: AssignmentReference
  ): Promise<Submission[] | undefined>;
  getSubmissionScores(
    assignmentReference: AssignmentReference
  ): Promise<number[]>;
}

export { useSubmissions };
export type { Submission };
