import React from 'react';
import { Tooltip, IconButton, IconButtonProps } from '@mui/material';

interface TooltipIconButtonProps extends IconButtonProps {
  icon: React.ElementType;
  tooltip: string;
}

const TooltipIconButton: React.FC<TooltipIconButtonProps> = ({
  icon: Icon,
  tooltip,
  ...rest
}) => {
  return (
    <Tooltip title={tooltip}>
      <div>
        <IconButton color="primary" {...rest}>
          <Icon />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default TooltipIconButton;
