import React, { ReactNode, useEffect, useMemo } from 'react';
import { Container } from '../../dependencyInjection/Container';
import { useAssignmentById } from './hooks/useAssignmentById';
import { useOrganizationData } from '../../utils/useOrganizationData';
import { useSubmissions } from './hooks/useSubmissions';
import NotAllowed from '../common/NotAllowed';
import { ReviewDetail } from '../review/ReviewDetail';
import { useSearchParams } from 'react-router-dom';
import { useApi } from '../../contexts/ApiProvider';

type AssignmentViewProps = {
  container: Container;
  getAssignmentId: () => string;
};

const AssignmentView: React.FC<AssignmentViewProps> = ({
  container,
  getAssignmentId,
}) => {
  const { data, isLoading } = useOrganizationData();

  const [searchParam, setSearchParams] = useSearchParams();

  const id = searchParam.get('id');
  const userId = searchParam.get('userId');

  const assignment = useAssignmentById(
    getAssignmentId(),
    userId
      ? container.createUserAssignmentProvider(userId).assignmentProvider
      : container.assignmentProvider
  );
  const submissions = useSubmissions(
    userId
      ? container.createUserAssignmentProvider(userId).submissionsProvider
      : container.submissionsProvider,
    assignment
  );

  const { api, analyticsApi } = useApi();

  useEffect(() => {
    if (id === null && submissions && submissions.length > 0) {
      setSearchParams({
        ...searchParam,
        id: submissions.at(0)!.submissionId,
      });
    }
  }, [id, submissions]);

  const currentIndex = useMemo(
    () =>
      (submissions || []).findIndex(
        (submission) => submission.submissionId === id
      ),
    [submissions, id]
  );

  if (isLoading) {
    return <div>loading organization data...</div>;
  }

  if (userId && (!data || !data.members.find(({ uid }) => uid === userId))) {
    return <NotAllowed />;
  }

  if (!assignment) {
    return <div>loading assignment...</div>;
  }

  if (!submissions) {
    return <div>loading submissions...</div>;
  }

  if (submissions.length === 0) {
    return <div>nothing here :/</div>;
  }

  if (!id || currentIndex < 0) {
    return <div>no file selected</div>;
  }

  const isFirstSubmission = currentIndex === 0;
  const isLastSubmission = currentIndex === submissions.length - 1;
  const currentSubmission = submissions[currentIndex];

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        const userId = api.getCurrentUserId();
        analyticsApi.logAssignmentViewCopyShareableLink(userId);
      },
      (err) => {
        console.error('Failed to copy: ', err);
      }
    );
  };

  const navigateAndUpdate = (navigateBy: -1 | 1) => {
    const nextId = submissions.at(currentIndex + navigateBy)?.submissionId;
    if (nextId) {
      setSearchParams({
        ...(userId && { userId }),
        id: nextId,
      });
      const uid = userId ?? api.getCurrentUserId();
      if (navigateBy === -1) {
        analyticsApi.logAssignmentViewPrevious(uid);
      } else {
        analyticsApi.logAssignmentViewNext(uid);
      }
    }
  };

  const navigationButton = (
    text: string,
    disabled: boolean,
    navigateBy: -1 | 1
  ): ReactNode => {
    return (
      <button
        onClick={() => {
          navigateAndUpdate(navigateBy);
        }}
        className={disabled ? 'owler-disabled-button' : 'owler-indigo-button'}
        disabled={disabled}
      >
        {text}
      </button>
    );
  };

  const exportButton = (): ReactNode => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const exportUrl = `${baseUrl}/export/${assignment.assignment_id}/${currentSubmission.submissionId}?token=${currentSubmission.token}`;

    return (
      <button
        onClick={() => copyToClipboard(exportUrl)}
        className="owler-indigo-button"
      >
        Copy Shareable Link
      </button>
    );
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="overflow-hidden rounded-lg bg-white flex flex-col h-full">
        <div className="flex justify-between px-6 py-4">
          {navigationButton('Previous', isFirstSubmission, -1)}
          <span>
            Submission {currentIndex + 1} of {submissions.length}
          </span>
          <div className="flex">
            {currentSubmission &&
              currentSubmission.submissionId &&
              currentSubmission.token && (
                <div className="mx-2">{exportButton()}</div>
              )}
            {navigationButton('Next', isLastSubmission, +1)}
          </div>
        </div>
        <ReviewDetail
          userId={userId}
          outputPath={currentSubmission?.fileName}
          assignment={assignment}
          submissionId={currentSubmission?.submissionId}
        />
      </div>
    </div>
  );
};

export default AssignmentView;
