import React from 'react';

import AssignmentMetadata from './AssignmentMetadata';
import SubmissionDetail from './SubmissionDetail';
import { useAssignmentDetails } from '../../../utils/useAssignmentDetails';
import { AssignmentWithUserId } from '../../../types';
import Loading from '../../common/Loading';

interface AssignmentDetailProps {
  data?: AssignmentWithUserId;
}

const AssignmentDetail: React.FC<AssignmentDetailProps> = ({ data }) => {
  const { data: assignmentDetail, isLoading } = useAssignmentDetails(
    data?.userId,
    data?.assignment_id
  );

  return (
    <div className="flex flex-col gap-4 px-16 py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h1 className="text-3xl font-bold">Assignment Details</h1>
          <AssignmentMetadata metadata={assignmentDetail?.metadata} />
          <SubmissionDetail
            userId={data?.userId}
            assignmentId={data?.assignment_id}
            submissions={assignmentDetail?.submissions}
            rubrics={assignmentDetail?.metadata?.rubric}
          />
        </>
      )}
    </div>
  );
};

export default AssignmentDetail;
