import { useQuery } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiProvider';
import {
  AssignmentRow,
  UserWithAssignmentDetails,
  AssignmentWithUserId,
  GradingJob,
  Member,
} from '../types';
interface OrganizationAssignments {
  data?: AssignmentRow[];
  rawData?: AssignmentWithUserId[];
}

interface AssignmentDataGridData extends OrganizationAssignments {
  isLoading: boolean;
  error: Error | null;
}

export const useAssignmentDataGridData = (
  memberList: Member[]
): AssignmentDataGridData => {
  const { api } = useApi();

  const formatLengthField = <T>(length: number, fieldName: string): string => {
    switch (length) {
      case 0:
        return `No ${fieldName}`;
      case 1:
        return `1 ${fieldName}`;
      default:
        return `${length} ${fieldName}s`;
    }
  };

  const getEvaluationTime = (job: GradingJob | null): string => {
    const start = job?.created_at;
    const end = job?.timestamp;

    const startDate: Date = start?.toDate() || new Date();
    const endDate: Date = end?.toDate() || new Date();

    const diffInMs: number = endDate.getTime() - startDate.getTime();

    const diffInSeconds: number = Math.floor(diffInMs / 1000);

    const minutes = Math.floor(diffInSeconds / 60);
    const seconds = diffInSeconds % 60;

    if (minutes > 0) {
      return `${minutes} min${minutes > 1 ? 's' : ''} and ${seconds} sec${
        seconds > 1 ? 's' : ''
      }`;
    } else {
      return `${seconds} sec${seconds > 1 ? 's' : ''}`;
    }
  };

  const convertData = (data: UserWithAssignmentDetails[]): AssignmentRow[] => {
    return data
      .map(
        ({ firstName, lastName, email, assignments, metadata, gradingJob }) =>
          assignments.map(
            ({
              assignment_id,
              assignment_metadata_id,
              grading_job_id,
              submissionCount,
              created_at,
            }) => ({
              id: assignment_id,
              teacherName: `${firstName || ''} ${lastName || ''}`.trim(),
              teacherEmail: email || '',
              course: metadata[assignment_metadata_id]?.course || '',
              assignmentName:
                metadata[assignment_metadata_id]?.question_truncated || '',
              submissionCount: formatLengthField(submissionCount, 'Submission'),
              // rubric: formatArrayLengthField(metadata?.rubric, 'Rubric'),
              // averageScore: getAverageScore(submissions),
              evaluationTime: getEvaluationTime(gradingJob[grading_job_id]),
              createdAt: created_at.toDate(),
            })
          )
      )
      .flatMap((arr) => arr);
  };

  const fetchOrgAssignments =
    async (): Promise<OrganizationAssignments | null> => {
      try {
        const userDetails =
          await api.getUserWithAssignmentDetailsFromIds(memberList);

        const convertedData = convertData(userDetails);

        const initalValue = [] as AssignmentWithUserId[];

        const assignmentList = userDetails.reduce(
          (acc, { assignments }) => [...acc, ...assignments],
          initalValue
        );

        return { data: convertedData, rawData: assignmentList };
      } catch (err) {
        const errorMessage =
          err instanceof Error ? err.message : 'An unknown error occurred.';
        console.error('Error fetching organization assignments:', errorMessage);
        throw error;
      }
    };

  const { data, isLoading, error } = useQuery<OrganizationAssignments | null>({
    queryKey: ['organizationAssignments', ...memberList.map(({ uid }) => uid)],
    queryFn: fetchOrgAssignments,
  });

  return {
    data: data?.data,
    rawData: data?.rawData,
    isLoading,
    error: error as Error | null,
  };
};
