import { AssignmentWithDetails } from '../types';
import { useApi } from '../contexts/ApiProvider';
import { useQuery } from '@tanstack/react-query';

interface AssignmentDetails {
  data?: AssignmentWithDetails | null;
  isLoading: boolean;
  error: Error | null;
}

export const useAssignmentDetails = (
  userId?: string,
  assignmentId?: string
): AssignmentDetails => {
  const { api } = useApi();

  const invalidReturnValue: AssignmentDetails = {
    data: null,
    isLoading: false,
    error: null,
  };

  if (!userId || !assignmentId) {
    return invalidReturnValue;
  }

  const fetchAssignmentWithDetails =
    async (): Promise<AssignmentWithDetails | null> => {
      try {
        const assignmentWithDetails =
          await api.getAssignmentsWithMetadataAndJobs(userId, assignmentId);
        return assignmentWithDetails;
      } catch (error) {
        console.error(
          `Error fetching assignment details for userId: ${userId}, assignmentId: ${assignmentId}`,
          error
        );
        throw error;
      }
    };

  const { data, isLoading, error } = useQuery<AssignmentWithDetails | null>({
    queryKey: ['assignmentDataWithDetails', userId, assignmentId],
    queryFn: fetchAssignmentWithDetails,
  });

  return { data, isLoading, error: error as Error | null };
};
